.partner {
    .navbar-container {
        background: var(--font-color-inverted);
    }
}

.partner--container {
    background-image: var(--bg-image);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    &.disabled {

        .partner--offers--card,
        .partner--offers--offers h2,
        .assets,
        .partner--offers--title,
        .tabs-container,
        .tabs-offers,
        .partner--offers--list,
        .partner--offers--group--functions,
        .partner--offers--group--discount,
        .partner--offers--price,
        .partner--offers--code-informations,

        .gallery,
        .links-container,
        .tabs-informations
        {
            opacity: 50%;
        }
    }

    .wwbr {
        border-color: rgba(255, 255, 255, 0.4);
    }

    .disabled-text {
        font-weight: $font-weight-bold;
        font-size: 1.8rem;
    }
}

.partner--hero {
    width: 100%;
    height: calc(100vh - 11.2rem);
    // background-image: linear-gradient(180deg, #03023B 0%, rgba(3, 2, 59, 0) 31.49%, #03023B 99.97%), var(--bg-image);
    background-image: linear-gradient(180deg,rgba(3, 2, 59, 0) 0, rgba(3, 2, 59, 0) 50%, #03023B 76%), var(--bg-image);
    // background-image: linear-gradient(180deg, #03023B 0%, rgba(3, 2, 59, 0) 25%, rgba(3, 2, 59, 0) 50%, #03023B 76%), var(--bg-image);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;

    @if $theme == 'lebeaujeu' {
        background-image: linear-gradient(180deg, rgba(3, 2, 59, 0) 20%, #000000 100%), var(--bg-image);
    }

    p {
        text-align: center;
        position: relative;
    }

    &.disabled {
        opacity: 30%;
    }
}
.partner--hero--bloc {
    height: 100%;
}

.partner--hero--title {
    line-height: 100%;

    em {
        font-style: normal;
        color: var(--color);
        display: block;
    }
}

.partner--hero--logo {
    width: 70%;
    aspect-ratio: 1/1;
    padding: 30px;
}

.partner--spacer {
    height: 8.1rem;
    @if $theme == 'lebeaujeu' {
        background: linear-gradient(#000000 0%, #FFFFFF00 100%);
    }
}

.partner--offers--card {
    img {
        transform: rotate(-5deg);
    }
}

.partner--offers--offers {
    h2 {
        font-size:4rem;
        line-height: 4rem;
        font-weight: $font-weight-bold;
    }

    .assets {
        font-size: 1.4rem;
    }

    .btn {
        padding-inline: 0;
    }
}

.partner--offers--countries {
    img {
        width: 24px;
    }
    .showMore {
        cursor: pointer;

        &.default {
            transform: rotate(180deg);
        }
    }
}

h3.partner--offers--title {
    font-size: 2.4rem;
    color: var(--primary-color);
    font-weight: $font-weight-bold;
}

.partner--offers--groups--label {
    padding-block: 0.8rem;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid $white-accent;

    &:first-of-type {
        border-top-left-radius: $border-radius-default;
        border-bottom-left-radius: $border-radius-default;
    }
    &:last-of-type {
        border-top-right-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;
    }

    &.active {
        background-color: $white-accent;
        color: $black;
    }

    .small {
        font-size: 1.2rem;
        opacity: 0.75;
        font-weight: $font-weight-regular;
    }
}

.partner--offers--offers--offer {
    font-size: 1.4rem;
    font-weight: $font-weight-regular;

    border-bottom: 0.1rem solid $white-accent;

    &.selected {
        background-color: $white-accent;
        color: $black;
    }

    &:first-of-type {
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: $border-radius-default;
    }
    &:last-of-type {
        border-bottom-left-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;
        border-bottom: none;
    }
}

.partner--offers--group--functions,
.partner--offers--group--discount {
    border-radius: $border-radius-default;
    background: #FFFFFF1A;
}

.partner--offers--group--functions {
    font-size: 1.4rem;
    font-weight: $font-weight-medium;

    li {
        margin-left: 1.6rem;
        padding-left: 0.8rem;
        list-style-image: url('/images/icons/check-circle-fill.svg');
    }
}

.partner--offers--price {
    font-size: 4rem;
    font-weight: $font-weight-semibold;
}

.partner--offers--group--discount {
    .picto {
        padding: 0.3rem 0.6rem;
        border-radius: $border-radius-small;
        background: rgba($white-accent, 0.4);
        color: $white-accent;
    }

    span {
        opacity: 0.7;
    }
}


.cta-how-to-use-code {
    position: relative;
    padding-left: 2.6rem;
    cursor: pointer;

    &::before {
        content: '';
        background-image: url('/images/icons/exclamation.svg');
        width: 1.6rem;
        height: 1.6rem;
        left: 0;
        position: absolute;
    }
}

.tabs-informations {
    strong {
        color: var(--color);
    }

    h2 {
        font-size: 5.2rem;
        font-weight: $font-weight-semibold;
        padding-bottom: 4rem;
    }

    h3 {
        font-size: 2.2rem;
        font-weight: $font-weight-semibold;
    }
}

#relatedPartners {
    h4 {
        font-size: 3rem;
        font-weight: $font-weight-bold;
    }
}

.gallery {
    display: grid;
    gap: 1.6rem;

    div {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: $border-radius-default;
    }
    &.large {
        width: 100%;
        aspect-ratio: 4/2;

        div {
            height: 100%;
            width: 100%;
        }
    }

    &.thumbnails {
        grid-template-columns: repeat(2, 1fr);

        div {
            aspect-ratio: 1/1;
        }
    }

    &.classic {
        grid-template-columns: repeat(2, 1fr);

        .large--image {
            grid-column: 1/-1;
            aspect-ratio: 3/2;
        }
    }
}

@if $theme == 'lebeaujeu' {
    #addOfferToBasket {
        border: 1px solid $default-color;
        background: linear-gradient(90deg, $default-color 0%, $black 100%);
        backdrop-filter: blur(50px);
        border-radius: 8px;
    }
}