// INFO: Déclaration des variables d'environnement
@use "./theme/default";
@use "./theme/lebeaujeu";

// INFO: La police par défaut
$font: 'Space Grotesk' !default;

// INFO: Les couleurs (thème par défaut)
$white: default.$white !default;
$white-accent: default.$white-accent !default;
$black: default.$black !default;
$color: default.$color !default;
$error: default.$error !default;
$input-error: default.$input-error !default;

$default-color: default.$default-color !default;
$default-color-hover: default.$default-color-hover !default;
$default-color-alpha: default.$default-color-alpha !default;

$secondary-color: default.$secondary-color !default;
$secondary-color-hover: default.$secondary-color-hover !default;
$light-blue: default.$light-blue !default;

$box-shadow: default.$box-shadow !default;
$light-background: default.$light-background !default;

// INFO: Les couleurs, par thème
@if $theme == 'lebeaujeu' {
    $white: lebeaujeu.$white;
    $white-accent: lebeaujeu.$white-accent;
    $black: lebeaujeu.$black;
    $color: lebeaujeu.$color;
    $error: lebeaujeu.$error;
    $input-error: lebeaujeu.$input-error;

    $default-color: lebeaujeu.$default-color;
    $default-color-hover: lebeaujeu.$default-color-hover;
    $default-color-alpha: lebeaujeu.$default-color-alpha;

    $secondary-color: lebeaujeu.$secondary-color;
    $secondary-color-hover: lebeaujeu.$secondary-color-hover;
    $light-blue: lebeaujeu.$light-blue;

    $box-shadow: lebeaujeu.$box-shadow;
    $light-background: lebeaujeu.$light-background;
}

// INFO: Les couleurs génériques
$primary-color: $default-color;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// INFO: Les marges
$spacers: (
    "0": 0,
    "4": .4rem,
    "8": .8rem,
    "10": 1rem,
    "16": 1.6rem,
    "20": 2rem,
    "26": 2.6rem,
    "32": 3.2rem,
    "36": 3.6rem,
    "40": 4rem,
    "52": 5.2rem,
    "60": 6rem,
    "80": 8rem,
    "100": 10rem,
    "120": 12rem,
    "150": 15rem,
    "160": 16rem,
    "200": 20rem,
    "auto": auto,
) !default;

// INFO: Les radius
$border-radius-xsmall: 0.2rem;
$border-radius-small: 0.4rem;
$border-radius-default: 0.8rem;
$border-radius-large: 1.6rem;

// INFO: Les grillades
$grid-columns: 8;
$gaps: (
    "4": 0.4rem,
    "8": 0.8rem,
    "10": 1rem,
    "16": 1.6rem,
    "20": 2rem,
    "24": 2.4rem,
    "32": 3.2rem,
    "40": 4rem,
    "48": 4.8rem,
    "56": 5.6rem,
    "80": 8rem,
    "100": 10rem,
    "120": 12rem,
    "160": 16rem,
);

// INFO: les medias queries
$breakpoints: (
    sm: 576px,
    md: 992px,
    lg: 1200px,
);

// INFO: Les différentes déclinaisons de couleurs (pour les boutons)
$btn-variations: (
    // name - fontColor - backgroundDefault - backgroundHoverStart - backgroundHoverEnd - hoverDirection
    'primary' $black var(--default-color) var(--default-color) var(--default-color-hover) 45deg,
    'secondary' $black var(--secondary-color) var(--secondary-color) var(--secondary-color-hover) 45deg,
    'white' $black $white #FAFAFA #e2dddd 180deg,
    'transparent' $white transparent transparent transparent 0deg,
    'halo' $white #00001E66 #00001E66 #00001E66 0deg,
);

@if $theme == 'lebeaujeu' {
    $btn-variations: (
        // name - fontColor - backgroundDefault - backgroundHoverStart - backgroundHoverEnd - hoverDirection
        'primary' $black var(--secondary-color) var(--secondary-color) var(--secondary-color-hover) 45deg,
        // 'primary' $white var(--default-color) var(--default-color) var(--default-color-hover) 45deg,
        'secondary' $black var(--secondary-color) var(--secondary-color) var(--secondary-color-hover) 45deg,
        'white' $black $white #FAFAFA #e2dddd 180deg,
        'transparent' $white transparent transparent transparent 0deg,
        'halo' $white #00001E66 #00001E66 #00001E66 0deg,
    );
}

$alert-variations: (
    // name - border - bgStart - bgEnd - fontColor
    'success' #9DCE2B #9DCE2B #B9E74C $black,
    'error' $error $error #DB3D4C $white,
    'culture' #bd1757 #bd1757 #bd1757 $white,
    'custom' var(--bg-color) var(--bg-color) var(--bg-color) var(--color),
);

$picto-variations: (
    // name - color - font
    'new' rgb(89, 163, 216) rgb(89, 163, 216) $black,
    'discount' rgb(252, 184, 62) rgb(252, 184, 62) $white,
    'primary' var(--default-color) var(--default-color) var(--font-color),
    'error' $error $error $white,
    'outline-not-available' $black var(--default-color) $white,
    'outline-alreadyPurchased' $black var(--default-color) $white,
);


:root {
    --font-color: #{$white};
    --font-color-inverted: #{$color};
    --default-color: #{$default-color};
    --default-color-hover: #{$default-color-hover};
    --default-color-alpha: #{$default-color-alpha};

    --white-accent: #{$white-accent};

    --primary-color: #{$primary-color};
    --secondary-color: #{$secondary-color};
    --secondary-color-hover: #{$secondary-color-hover};
}